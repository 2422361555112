'use client';

import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { Value, isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

import Button from '@/components/button.component';
import Input from '@/components/input.component';
import PhoneInput from '@/components/phone-input';
import { CountryCodeTypes } from '@/constants/country-codes';
import useTranslationStore from '@/store/translation';

import SendNextBlackIcon from '../icons/send-next-black-icon';
import SendNextIcon from '../icons/send-next-icon';
import { MessageBoxStyle } from './message-box.component';

export const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .when('emailStatus', {
      is: true,
      then: (schema) =>
        schema
          .email('This field should be a valid email address.')
          .when('emailRequired', {
            is: true,
            then: (schema) => schema.required('This field is required!'),
          }),
      otherwise: (schema) => schema,
    }),
  phone: yup
    .string()
    .trim()
    .when('phoneStatus', {
      is: true,
      then: (schema) =>
        schema
          .test({
            name: 'isValidPhoneNumber',
            test: function (value) {
              return !value || isValidPhoneNumber(value as Value);
            },
            message: 'This field should be a valid phone number.',
          })
          .when('phoneRequired', {
            is: true,
            then: (schema) => schema.required('This field is required!'),
          }),
      otherwise: (schema) => schema,
    }),
  fullName: yup
    .string()
    .trim()
    .when('fullNameStatus', {
      is: true,
      then: (schema) =>
        schema.when('fullNameRequired', {
          is: true,
          then: (schema) => schema.required('This field is required!'),
        }),
      otherwise: (schema) => schema,
    }),
  emailStatus: yup.boolean(),
  phoneStatus: yup.boolean(),
  fullNameStatus: yup.boolean(),
  emailRequired: yup.boolean(),
  phoneRequired: yup.boolean(),
  fullNameRequired: yup.boolean(),
});

type FormData = {
  email?: string;
  phone?: string;
  fullName?: string;
  emailRequired?: boolean;
  phoneRequired?: boolean;
  fullNameRequired?: boolean;
  emailStatus?: boolean;
  phoneStatus?: boolean;
  fullNameStatus?: boolean;
};

type CollectUserDataProps = {
  className?: string;
  handleSubmit?: (value: FormData) => void;
  style: MessageBoxStyle;
  collectUserData: {
    fullName: { status: boolean; required: boolean };
    phone: { status: boolean; required: boolean; countryCode?: string | null };
    email: { status: boolean; required: boolean };
    status: boolean;
    after: number;
  };
};

const CollectUserData: FC<CollectUserDataProps> = ({
  className,
  handleSubmit,
  style,
  collectUserData,
}) => {
  const { getValue } = useTranslationStore();

  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      phone: '',
      fullName: '',
      emailStatus: collectUserData.email.status,
      phoneStatus: collectUserData.phone.status,
      fullNameStatus: collectUserData.fullName.status,
      emailRequired: collectUserData.email.required || false,
      phoneRequired: collectUserData.phone.required || false,
      fullNameRequired: collectUserData.fullName.required || false,
    },
  });

  const onSubmit = handleFormSubmit((data: FormData) => {
    if (handleSubmit) handleSubmit(data);
  });

  const isFormComplete = () => {
    const values = watch();
    const requiredFields = [
      { field: 'email', status: collectUserData.email.status },
      { field: 'phone', status: collectUserData.phone.status },
      { field: 'fullName', status: collectUserData.fullName.status },
    ];

    return requiredFields.every(
      ({ field, status }) =>
        !status ||
        (values[field as keyof FormData] && !errors[field as keyof FormData]),
    );
  };

  return (
    <div
      className={cn(
        'mt-3 inline-flex w-full flex-col sm:max-w-[273px]',
        className,
      )}
    >
      <div
        data-testid="collect-user-data-wrapper"
        className="w-full rounded-lg px-[18px] py-[15px]"
        style={{
          background:
            style?.backgroundColor ||
            'linear-gradient(339.94deg, #F4FBFF 55.36%, rgba(244, 251, 255, 0) 98.66%)',
          color: style.textColor,
          border: `1px solid ${style.borderColor}`,
        }}
      >
        <h6 data-testid="collect-user-data-title" className="mb-[15px] text-sm">
          {getValue('collectUserDataLabel')}
        </h6>
        <form
          data-testid="collect-user-data-form"
          id="collect-user-data-input-wrapper"
          className="flex min-w-[200px] flex-col items-center gap-2 rounded-md py-[4px]"
          onSubmit={onSubmit}
        >
          {collectUserData.email.status && (
            <Input
              className="w-full"
              inputClassName="h-[34px] w-full px-3 py-[7px] rounded-lg border border-solid border-[#E5E7EB] text-sm"
              errorClassName="text-xs"
              placeholder={getValue('emailPlaceholder')}
              aria-label="email input"
              data-testid="collect-email-input"
              {...register('email')}
              error={errors.email}
            />
          )}
          {collectUserData.phone.status && (
            <PhoneInput
              className="w-full"
              id="phone"
              placeholder={getValue('phonePlaceholder')}
              error={errors?.phone}
              errorClassName="text-xs"
              value={watch('phone')}
              onChange={(value) => {
                setValue('phone', value, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              defaultCountry={
                (collectUserData.phone.countryCode as CountryCodeTypes) || 'US'
              }
              small
            />
          )}
          {collectUserData.fullName.status && (
            <Input
              className="w-full"
              inputClassName="h-[34px] w-full px-3 py-[7px] rounded-lg border border-solid border-[#E5E7EB] text-sm"
              errorClassName="text-xs"
              placeholder={getValue('fullNamePlaceholder')}
              aria-label="full name input"
              data-testid="collect-full-name-input"
              {...register('fullName')}
              error={errors.fullName}
            />
          )}
          <Button
            variant="unstyled"
            data-testid="collect-user-data-submit"
            type="submit"
            className="aspect-square h-[34px] self-end rounded bg-black/[0.06] !px-[7px] !py-[12px] transition-colors duration-200 hover:bg-black/20"
          >
            {isFormComplete() ? (
              <SendNextBlackIcon className="h-[20px] w-[20px]" />
            ) : (
              <SendNextIcon className="h-[20px] w-[20px]" />
            )}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CollectUserData;
