import { FC } from 'react';

import cn from 'classnames';
import Link from 'next/link';

type MessengerFooterProps = {
  className?: string;
  removeBranding?: boolean;
};
const MessengerFooter: FC<MessengerFooterProps> = ({
  className,
  removeBranding,
}) => {
  return (
    <div
      id="messenger-footer"
      className={cn('h-[22px] bg-[#F5F5F5] px-[15px] py-[6px]', className)}
    >
      {removeBranding ? null : (
        <Link
          href="https://livechatai.com/"
          target="_blank"
          className="block text-center text-[8px] leading-[10px] text-[#777777]"
        >
          Powered by LiveChatAI
        </Link>
      )}
    </div>
  );
};

export default MessengerFooter;
