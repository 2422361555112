'use client';

import { FC, useMemo, useState } from 'react';

import cn from 'classnames';
import { toast } from 'react-toastify';

import createAgentUnreadChats from '@/app/actions/createAgentUnreadChats';
import { insertResolutionLog } from '@/app/actions/insertResolutionLog';
import { requestHumanSupport } from '@/app/actions/requestHumanSupport';
import { updateChats } from '@/app/actions/updateChats';
import WarningToast from '@/components/toasts/warning-toast.component';
import {
  messagesStore,
  messengerStore,
  useMessengerContext,
} from '@/context/messengerContext';
import { useWorkspaceUsers } from '@/hooks/useWorkspaceUsers';
import useTranslationStore from '@/store/translation';
import { addAlpha } from '@/utils/colors';

import Avatar from '../avatar.component';
import DownVoteFillIcon from '../icons/downvote-fill-icon.component';
import DownVoteIcon from '../icons/downvote-icon.component';
import UpvoteFillIcon from '../icons/upvote-fill-icon.component';
import UpvoteIcon from '../icons/upvote-icon.component';
import { MessageBoxStyle, MessegeBoxVariant } from './message-box.component';

export type ResolutionFormEvent = 'AIHelped' | 'AIDidNotHelp' | 'TalkToAgent';

type ResolutionFormProps = {
  variant: MessegeBoxVariant;
  activeChatId?: string;
  style: MessageBoxStyle;
  handleClickButton?: (messageId: string, event: ResolutionFormEvent) => void;
  showHumanSupportButton?: boolean;
};

const ResolutionForm: FC<ResolutionFormProps> = ({
  variant = 'default',
  activeChatId,
  style: { backgroundColor, textColor, borderColor },
  showHumanSupportButton,
  handleClickButton,
}) => {
  const {
    chatbot: {
      workspaceId: workspaceId,
      isLiveChatEnabled,
      liveChatRoutingMessage,
      isSatisfactionSurveyEnabled,
    },
  } = useMessengerContext();

  const { getValue } = useTranslationStore();

  const { data: workspaceUsers } = useWorkspaceUsers({ workspaceId });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClickSolved = async () => {
    if (!activeChatId) return;

    setIsLoading(true);

    try {
      const log = await insertResolutionLog({
        chatId: activeChatId,
        isAIHelped: true,
      });

      handleClickButton && handleClickButton(log.messageId, 'AIHelped');
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      toast(<WarningToast type="embed" detail={getValue('defaultError')} />);
    }
  };

  const handleClickNotSolved = async () => {
    if (!activeChatId) return;

    setIsLoading(true);

    try {
      const log = await insertResolutionLog({
        chatId: activeChatId,
        isAIHelped: false,
      });

      handleClickButton && handleClickButton(log.messageId, 'AIDidNotHelp');
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      toast(<WarningToast type="embed" detail={getValue('defaultError')} />);
    }
  };

  const handleClickTalkAgent = async () => {
    if (!activeChatId) return;

    setIsLoading(true);

    try {
      await insertResolutionLog({
        chatId: activeChatId,
        isAIHelped: false,
      });

      await updateChats({
        chatId: activeChatId,
        isAIHelped: false,
        //If the user clicks on the "Talk to an agent" button, the AI leaves the conversation and the agent takes over.
        isAgent: true,
      });
      const message = await requestHumanSupport({
        chatId: activeChatId,
        isQuotaLimitReached: true,
      });
      await createAgentUnreadChats(activeChatId);

      messagesStore.getState().updateMessage(message.id, {
        isHumanSupportRequest: true,
        isQuotaLimitReached: true,
      });
      messengerStore.getState().updateActiveChat({
        isAIHelped: false,
        isAgent: true,
      });

      handleClickButton && handleClickButton(message.id, 'TalkToAgent');
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      toast(<WarningToast type="embed" detail={getValue('defaultError')} />);
    }
  };

  const showRequestAgentButton = useMemo(() => {
    if (!showHumanSupportButton) return false;
    if (!isLiveChatEnabled) return false;

    const activeChat = messengerStore.getState().activeChat;

    if (activeChat.isResolved) return true;
    if (activeChat.isAIHelped) return true;

    return false;
  }, [isLiveChatEnabled, showHumanSupportButton]);

  if (!showRequestAgentButton && !isSatisfactionSurveyEnabled) return null;

  if (variant === 'minimal') {
    const style = {
      background: addAlpha(textColor, 0.1),
      color: textColor,
    };

    return (
      <div
        className={cn(
          'grid w-full items-center gap-[5px] px-[18px] py-[15px]',
          {
            'grid-cols-[38px_38px_auto]':
              isSatisfactionSurveyEnabled && showRequestAgentButton,
            'grid-cols-[38px_38px]':
              isSatisfactionSurveyEnabled && !showRequestAgentButton,
            'grid-cols-1':
              !isSatisfactionSurveyEnabled && showRequestAgentButton,
          },
        )}
      >
        {isSatisfactionSurveyEnabled ? (
          <>
            <button
              onClick={handleClickNotSolved}
              disabled={isLoading}
              className="group aspect-square rounded-full p-[10px] transition-transform hover:-translate-y-0.5 disabled:cursor-not-allowed disabled:opacity-50"
              style={style}
              data-tooltip-id="messenger-tooltip"
              data-tooltip-content="That didn’t help"
              data-tooltip-offset={10}
            >
              <DownVoteIcon className="inline-block group-hover:hidden" />
              <DownVoteFillIcon className="hidden group-hover:inline-block" />
            </button>
            <button
              onClick={handleClickSolved}
              disabled={isLoading}
              className="group aspect-square rounded-full p-[10px] transition-transform hover:-translate-y-0.5 disabled:cursor-not-allowed disabled:opacity-50"
              style={style}
              data-tooltip-id="messenger-tooltip"
              data-tooltip-content="That helped"
              data-tooltip-offset={10}
            >
              <UpvoteIcon className="inline-block group-hover:hidden" />
              <UpvoteFillIcon className="hidden group-hover:inline-block" />
            </button>
          </>
        ) : null}

        {showRequestAgentButton ? (
          <button
            onClick={handleClickTalkAgent}
            disabled={isLoading}
            className="flex w-auto items-center justify-center whitespace-normal break-words rounded-[19px] py-[7px] pl-[7px] pr-[15px] text-left transition-transform hover:-translate-y-0.5 disabled:cursor-not-allowed disabled:opacity-50 sm:w-max"
            style={style}
          >
            {workspaceUsers && !!workspaceUsers.length ? (
              <Avatar
                className="ring-0"
                size={24}
                src={workspaceUsers[0].avatar}
                email={workspaceUsers[0].email}
              />
            ) : null}
            <span className="ml-[6px] inline-block text-[14px] font-normal leading-5">
              {liveChatRoutingMessage}
            </span>
          </button>
        ) : null}
      </div>
    );
  }

  return (
    <div
      style={{
        background:
          backgroundColor ||
          'linear-gradient(339.94deg, #F4FBFF 55.36%, rgba(244, 251, 255, 0) 98.66%)',
        color: textColor,
        border: `1px solid ${borderColor}`,
      }}
      className={cn(
        'mr-auto mt-3 w-full max-w-full rounded-lg px-[15px] py-3 sm:w-fit',
      )}
    >
      <p className="break-words text-sm">{getValue('resolutionQuestion')}</p>
      <div className="mt-4 space-y-2">
        {isSatisfactionSurveyEnabled ? (
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
            <button
              onClick={handleClickSolved}
              disabled={isLoading}
              className="w-full rounded-full border border-[rgba(0,0,0,0.20)] px-3 py-[6px] text-xs font-medium transition-transform hover:-translate-y-0.5 disabled:cursor-not-allowed disabled:opacity-50 md:px-[14px]"
            >
              {getValue('helped')}
            </button>
            <button
              onClick={handleClickNotSolved}
              disabled={isLoading}
              className="w-full rounded-full border border-[rgba(0,0,0,0.20)] px-3 py-[6px] text-xs font-medium transition-transform hover:-translate-y-0.5 disabled:cursor-not-allowed disabled:opacity-50 md:px-[14px]"
            >
              {getValue('notHelped')}
            </button>
          </div>
        ) : null}

        {showRequestAgentButton ? (
          <button
            onClick={handleClickTalkAgent}
            disabled={isLoading}
            className="flex w-full items-center justify-center gap-[10px] rounded-full border border-[rgba(0,0,0,0.20)] px-3 py-[6px] text-xs font-medium transition-transform hover:-translate-y-0.5 disabled:cursor-not-allowed disabled:opacity-50 md:px-[14px]"
          >
            {workspaceUsers && !!workspaceUsers.length ? (
              <div className="flex scale-75 -space-x-1 md:scale-100">
                {workspaceUsers.map((user) => (
                  <Avatar
                    className="ring-2 ring-white"
                    key={user.id}
                    size={28}
                    src={user.avatar}
                    email={user.email}
                  />
                ))}
              </div>
            ) : null}
            {liveChatRoutingMessage}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default ResolutionForm;
